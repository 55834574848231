.forgot-password-section {
  width: 455px;
  color: #002c5f;
  display: flex;
  flex-direction: column;
  gap: 25px;
  transform: translateY(-55px);
}

.forgot-password-section .password-header {
  font-weight: 600;
  font-size: 24px;
}

.forgot-password-section .password-sub-head {
  font-weight: 500;
  font-size: 14px;
}

.forgot-password-section .login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
  .forgot-password-section {
    width: 100%;
    transform: translateY(-45px);
  }
}
