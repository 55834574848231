.dash-card {
  height: 450px;
  width: 100%;
  box-shadow: 0px 10px 20px #0000000f;
  border-radius: 30px;
  padding: 15px;
  padding-bottom: 25px;
  cursor: pointer;
}

.dash-card .dash-card-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dash-card-box .dash-card-picture {
  height: 70%;
  width: 100%;
}
.dash-card-picture img,
.dash-card-picture video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dash-card-box .dash-card-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #002c5f;
}
.dash-card-body .card-body-head {
  font-size: 20px;
  font-weight: 500;
}
.dash-card-body .card-body-sub {
  font-size: 18px;
  font-weight: 500;
  color: #c3c3c3;
  text-transform: capitalize;
}
.dash-card-body .progress-head {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  padding-top: 5px;
}
.dash-card-body .progress-bar {
  width: 100%;
  background-color: #eeeeee;
  border-radius: 10px;
}
.progress-bar #mybar {
  width: 15%;
  height: 10px;
  background-color: #002c5f;
  border-radius: 10px;
}

.card-skeleton {
  height: 450px;
  width: 100%;
  box-shadow: 0px 10px 20px #0000000f;
  border-radius: 30px;
  padding: 15px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 20pxx;
}
.skeleton-img {
  margin-bottom: 20px;
}
.skeleton-name {
  margin-bottom: 20px;
}
