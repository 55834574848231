.cp-page {
  margin-top: 100px;
  width: 100%;
}

.cp-page .page-content .page-header {
  height: 120px;
  background-color: #002c5f;
  color: #ffffff;
}
.page-header .cp-h-box {
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.cp-h-box .cp-head {
  font-size: 40px;
  font-weight: normal;
  font-family: "Times New Roman", Times, serif;
  text-transform: capitalize;
}

.cp-h-box .cp-sub {
  font-size: 16px;
  font-weight: normal;
  font-family: "Times New Roman", Times, serif;
  text-align: right;
}

.cp-h-box .btn-proj {
  width: 200px;
}

@media (max-width: 68.75em) {
  /* For Tab-Land  68.75em= 1100px/16px */
  .body-box .body-grid {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  .cp-h-box .cp-head {
    font-size: 35px;
  }

  .header-box .sub {
    font-size: 16px;
  }
}
@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */

  .page-header .h-box {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
  }
  .cp-h-box .head {
    font-size: 26px;
  }

  .header-box .sub {
    font-size: 14px;
  }
}
