.upload-box {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}
.upload-box .header {
  font-size: 20px;
  font-weight: 800;
  color: #002c5f;
  text-align: center;
}
.upload-box .sub {
  margin-top: -15px;
  font-size: 16px;
  font-weight: 500;
  color: #959595;
  text-align: center;
}

.upload-box .upload-close {
  position: absolute;
  top: 40px;
  right: 65px;
  cursor: pointer;
  font-size: 18px;
}

.upload-box .upload-form {
  width: 100%;
}
.upload-form .uploader {
  height: 200px;
  width: 100%;
  background-color: #f4f5f9;
}
.uploader .uploader-dis {
  height: 200px;
  width: 100%;
  background: #f4f5f9;
  width: 100%;
  cursor: pointer;
  overflow-y: scroll;
}
.uploader-dis video {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.uploader-dis img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.uploader .uploader-icon {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.uploader-icon :nth-child(1) {
  font-size: 80px;
  color: rgba(0, 44, 95, 0.8);
}
.uploader-icon .uploader-text {
  color: #959595;
  font-weight: 500;
}
.upload-form .upload-btn {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}
.upload-btn > :nth-child(2) {
  background: #fff;
  color: #002c5f;
}
.upload-btn > :nth-child(2):hover {
  background: #252525;
  color: #fff;
}
.upload-form .button {
  height: 48px;
  width: 100px;
}

@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
}
