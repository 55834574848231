.acc-box {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}
.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}
.header .header-icon {
  height: 68px;
  width: 68px;
  border-radius: 50%;
  background: transparent linear-gradient(145deg, #1eb4fa 0%, #105ffb 100%) 0%
    0% no-repeat padding-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-icon > * {
  font-size: 45px;
  color: #f4feff;
}
.header-icon > img {
  object-fit: contain;
}
.header .icon-load {
  background: #f7f7f7;
  color: #303c47;
  padding: 10px 30px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.hide {
  display: none;
}

.acc-form {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}

.sm {
  display: flex;
  gap: 15px;
}

.acc-form .pass-change {
  color: #ff0000;
  text-decoration: underline solid #ff0000 1px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.acc-form .button {
  height: 48px;
  margin-top: 20px;
}
@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
  .sm {
    flex-direction: column;
  }
  .header .icon-load {
    padding: 10px 20px;
  }
}
