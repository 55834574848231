.reset-password-section {
  width: 455px;
  color: #002c5f;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.reset-password-section .password-header {
  font-weight: 600;
  font-size: 24px;
}

.reset-password-section .password-sub-head {
  font-weight: 500;
  font-size: 14px;
}
.password-sub-head span {
  font-weight: 700;
}

.reset-password-section .login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.login-form .otp-field {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.otp-field .otp-input {
  height: 52px;
  width: 58px;
  border: 2px solid rgba(1, 27, 96, 0.8);
  border-radius: 5px;
  background: #fff;
  text-align: center;
  padding: 0;
  color: #011b60;
  font-size: 24px;
  font-weight: 500;
}
.otp-field .otp-input:focus {
  outline: none;
}
.reset-password-section button {
  width: 200px;
}

.login-form .btn-group {
  display: flex;
  justify-content: space-between;
}

.btn-group .btn {
  display: flex;
  justify-content: center;
  gap: 13px;
  align-items: center;
  background: none;
  color: #002c5f;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.btn .arrow {
  font-size: 25px;
  transform: translate(0, 3px);
  transition: all 0.2s ease-in-out;
}
.btn-group .btn:hover > .arrow {
  transform: translate(-8px, 3px);
}

@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
  .reset-password-section {
    width: 100%;
    transform: translateY(-50px);
  }
  /* .otp-field .otp-input {
    height: 40px;
    width: 44px;
  } */
  .login-form .btn-group {
    gap: 15px;
    flex-direction: column;
  }
  .reset-password-section button {
    width: 100%;
  }
}
@media (max-width: 26.25em) {
  /* For Phone  26.25em= 420px/16px */
  .otp-field .otp-input {
    height: 40px;
    width: 44px;
  }
}
