.project-activity .activity-head {
  padding: 20px 15px;
  background-color: #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: #1b2c59;
}
.activity-head .p-manager {
  font-size: 14px;
  font-weight: 500;
}
.p-manager span {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}
.p-comment {
  color: #002c5f;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  text-align: right;
  cursor: pointer;
}
.activity-body {
  padding: 0px 15px 5px 15px;
  max-height: 472px;
  overflow: hidden;
}
.activity-display {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.activity-display::-webkit-scrollbar {
  display: none;
}
.p-activity {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.p-activity .activity-key {
  color: #002c5f;
  font-size: 16px;
  font-weight: 500;
}
.p-activity .completed {
  font-weight: 800;
  color: #51ac06;
  font-size: 18px;
}
.p-activity .ongoing {
  font-weight: 800;
  color: #ff9d00;
  font-size: 18px;
  text-transform: capitalize;
}
.p-activity .yet {
  font-weight: 800;
  color: #ff0202;
  font-size: 18px;
}
.break {
  display: none;
}
.no-activity {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #002c5f;
}
/* MEDIA QUERY */ ;
