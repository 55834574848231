.login-section {
  width: 455px;
  color: #002c5f;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transform: translateY(-30px);
}

.login-section .login-header {
  font-weight: 600;
  font-size: 24px;
}

.login-section .login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.login-form .f-pass {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: fit-content;
}

@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
  .login-section {
    width: 100%;
    transform: translateY(-50px);
  }
}
