.button {
  font-size: 16px;
  color: #ffffff;
  background: #002c5f;
  width: 100%;
  height: 58px;
  border: #707070 1px solid;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}
/* .button:hover {
  background: #252525;
} */
.button-disabled {
  font-size: 16px;
  color: #fae9cd;
  background: #0d2e5f;
  width: 100%;
  height: 4rem;
  border: none;
  cursor: pointer;
  background: #dadde7;
  position: relative;
}
.button__text {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
  .button {
    height: 48px;
  }
}
