.popup-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 150;
}
.popup-modal .popup-box {
  width: 520px;
  max-height: 80%;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 15px;
  padding: 45px 60px 60px 60px;
  position: relative;
}

.popup-modal .popup-box::-webkit-scrollbar {
  display: none;
}

.popup-box .popup-box-close {
  position: absolute;
  top: 30px;
  right: 65px;
  cursor: pointer;
  font-size: 18px;
}

@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
  .popup-modal .popup-box {
    width: 90%;
    padding: 45px 30px 30px 30px;
  }
  .popup-box .popup-box-close {
    right: 35px;
  }
}
