.m-progress {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}
.m-progress .header {
  font-size: 20px;
  font-weight: 800;
  color: #002c5f;
  text-align: center;
}
.m-progress .mp-progress {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 30px;
}
.mp-progress .mp {
  width: 350px;
  height: 350px;
  display: "flex";
  align-items: "center";
  justify-content: center;
  position: relative;
}
.mp input {
  height: 60px;
  width: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  text-align: center;
  border: solid #ff9d00 3px;
  border-radius: 5px;
  color: #002c5f;
}
.manager-edit {
  height: 80px;
  width: 100%;
}
