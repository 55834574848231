.warning-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.warning-text {
  font-size: 24px;
  color: #002c5f;
  text-align: center;
}

.btn-holder {
  display: flex;
  gap: 20px;
}
.btn-holder > * {
  height: 40px;
}
.btn-holder > :nth-child(1):hover {
  background-color: #51ac06;
}

.btn-holder .button__text {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.btn-holder .button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.btn-holder .button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
.btn-holder > :nth-child(2):hover {
  background-color: #ff0202;
}
