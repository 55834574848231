.select-box {
  width: 100%;
  position: relative;
}
.select-option {
  position: relative;
}
.select-option input {
  width: 100%;
  background: #f4f5f9;
  color: #011b60;
  padding: 8px 15px;
  height: 40px;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  border: 0 !important;
  margin-top: 10px;
  outline: 0 !important;
}

.select-option::after {
  content: "";
  border-top: 10px solid #333;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  right: 15px;
  top: 50%;
  transition: all 0.4s ease;
}

.content {
  background: #d0d0d0;
  position: absolute;
  color: #011b60;
  border-radius: 7px;
  margin-top: 5px;
  width: 100%;
  z-index: 500;
  padding: 15px;
  display: none;
}

.search input {
  width: 100%;
  font-size: 14px;
  padding: 10px;
  outline: 0;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
}

.options {
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
}
.options::-webkit-scrollbar {
  width: 8px;
  background: #999;
  border-radius: 0;
}
.options::-webkit-scrollbar-thumb {
  background: #525861;
  border-radius: 0;
}
.options li {
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
}
.options li:hover {
  background: rgba(255, 255, 255, 0.7);
}

.select-box.active .content {
  display: block;
}

.select-box.active .select-option::after {
  transform: rotateX(-180deg);
}

/* .contatainer {
  width: 100%;
}

.select-box {
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: relative;
}

.select-box .options-container {
  background: #d0d0d0;
  color: #011b60;
  max-height: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.4s;
  overflow: hidden;
  position: absolute;
  top: 50px;
  z-index: 100;
  order: 1;
}

.selected {
  background: #f4f5f9;
  padding: 8px 15px;
  height: 40px;
  width: 100%;
  margin-bottom: 8px;
  color: #011b60;
  position: relative;
  cursor: pointer;
  order: 0;
}

.selected-icon {
  position: absolute;
  height: "100%";
  width: 32px;
  right: 10px;
  font-size: 20px;
  top: 11px;
  color: #011b60;

  transition: all 0.4s;
}

.select-box .options-container.active {
  max-height: 240px;
  opacity: 1;
  overflow-y: scroll;
}

.selected-icon-active {
  position: absolute;
  width: 32px;
  right: 10px;
  font-size: 20px;
  top: 11px;
  color: #011b60;
  transform: rotateX(180deg);
}
.select-box .options-container::-webkit-scrollbar {
  width: 8px;
  background: #999;
  border-radius: 0;
}
.select-box .options-container::-webkit-scrollbar-thumb {
  background: #525861;
  border-radius: 0;
}

.select-box .option {
  box-sizing: border-box;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;
}
.select-box .option:hover {
  background: rgba(255, 255, 255, 0.7);
}
.select-box label {
  cursor: pointer;
}
.select-box .option .radio {
  display: none;
} */
