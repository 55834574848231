.footer {
  width: 100%;
  height: 50px;
  background: #002c5f;
  position: fixed;
  bottom: 0;
  transition: all 300ms linear;
  z-index: 100;
}
.footer .footer-box {
  max-width: 1440px;
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-box .footer-content {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.scroll-down .footer {
  transform: translate3d(0, 100%, 0);
}
.scroll-up .footer {
}
