.pass-box {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}
.pass-box .pass-header {
  font-size: 20px;
  font-weight: 800;
  color: #002c5f;
}
.pass-box .pass-form {
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
}
.pass-form .form-input {
  height: 48px;
}

.pass-form input {
  font-size: 18px;
}
.pass-form .form-input .input-label {
  left: 31px;
  transform: translateY(-50%);
  color: #011b60;
  font-size: 14px;
  font-weight: 500;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
}

.pass-form .form-input input:focus ~ .input-label,
.pass-form .form-input input:valid ~ .input-label {
  transform: translateY(-32px);
  font-size: 12px;
}

.pass-form .button {
  height: 48px;
  margin-top: 10px;
}
@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */

  .pass-page .pass-box {
    width: 90%;
    padding: 45px 30px 30px 30px;
  }
}
