.np-box {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    width: 100%;
  }
  
  .np-box .header {
    width: 100%;
    font-size: 20px;
    font-weight: 800;
    color: #002c5f;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  
  .np-box .np-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .np-form .button {
    margin-top: 15px;
    height: 48px;
  }
   img{
     width: 100%;
     height: auto;
     border-radius: 20PX;
  }