.edit-button {
  background-color: white;
  color: #002c5f;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  cursor: pointer;
}
