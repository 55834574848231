.login-page {
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
}

.login-page .login-box {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 50px;
}

.login-box .content {
  background-color: transparent;
  display: flex;
  flex: 1;
  align-items: center;
  /* height: 100%; */
  position: relative;
  padding-right: 50px;
  z-index: 10;
  overflow: visible;
}
.content-logo {
  position: absolute;
  top: 50px;
}
.content-logo img {
  width: 150px;
}

.content .content-body {
  margin-top: 150px;
  display: flex;
  align-items: center;
}

.login-box .login-image {
  flex: 1.5;
  min-height: 100vh;
  max-height: 717px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
  background: #fff;
}
.login-image .carousel {
  height: 100%;
}
.carousel {
  height: 100%;
}
.login-image .slider-wrapper,
.login-image .slide,
.login-image .slider {
  height: 100%;
  width: 100%;
  background: #fff;
}
.carousel .selected .slide img {
  width: 100%;
  overflow: hidden;
}
.carousel .selected {
  overflow: hidden;
}
.carousel .slide img {
  object-fit: cover;
}
.login-image .shape {
  width: fit-content;
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  justify-content: center;
  gap: 8px;
  z-index: 0;
}
.shape .shape-lg {
  width: 80px;
  height: 10px;
  border-radius: 5px;
  background-color: #0c44c1;
}

.shape .shape-sm {
  width: 20px;
  height: 10px;
  border-radius: 5px;
  background-color: #e8e8e8;
}

@media (max-width: 50em) {
  /* For Tab-port  50em= 800px/16px */
  .login-image .shape {
    display: none;
  }
}
@media (max-width: 37.5em) {
  /* For Tab-port  37.5em= 600px/16px */
  .login-image {
    display: none;
  }
  .login-box .content {
    min-height: 100vh;
    display: block;
  }
  .content .content-body {
    margin-top: 250px;
    padding-bottom: 150px;
  }
  .login-page .login-box {
    padding-left: 20px;
  }

  .login-box .content {
    padding-right: 20px;
    width: 100%;
  }
}
