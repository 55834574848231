.form-input {
  position: relative;
  height: 58px;
}

.form-input input {
  height: 100%;
  width: 100%;
  display: block;
  background: none;
  border: 2px solid rgba(1, 27, 96, 0.8);
  opacity: 1;
  color: #002c5f;
  font-size: 20px;
  padding-left: 31px;
  padding-right: 50px;
  user-select: none;
}
.form-input input:focus {
  outline: none;
  border: 2px solid #0c44c1;
}
.form-input input:-webkit-autofill,
.form-input input:-webkit-autofill:hover,
.form-input input:-webkit-autofill:focus,
.form-input input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}
.form-input input:-webkit-autofill {
  -webkit-text-fill-color: #011b60 !important;
}
.form-input .input-label {
  position: absolute;
  top: 50%;
  left: 31px;
  transform: translateY(-50%);
  color: #011b60;
  font-size: 16px;
  font-weight: 500;
  pointer-events: none;
  transition: all 0.15s ease-in-out;
}

.form-input input:focus ~ .input-label,
.form-input input:valid ~ .input-label {
  transform: translateY(-38px);
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  padding: 0 5px;
}
.form-input .eye {
  position: absolute;
  top: 55%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 22px;
  cursor: pointer;
  opacity: 0.7;
}

.form-input .error-msg {
  font-size: 14px;
  font-weight: 400;
  color: rgb(195, 48, 48);
  transform: translateX(32px);
  height: fit-content;
}

.form-profile-input {
  box-sizing: border-box;
  height: fit-content;
}
.form-profile-input input:focus {
  outline: none;
  background: #eeeeee;
}
.form-profile-input .profile-input-label {
  color: #011b60;
  font-size: 12px;
  font-weight: 600;
  padding: 15px 15px;
}
.form-profile-input input,
.dropDown-input {
  height: 40px;
  width: 100%;
  display: block;
  background: #f4f5f9;
  border: none;
  opacity: 1;
  color: #011b60;
  font-size: 16px !important;
  padding-left: 15px;
  padding-right: 15px;
  user-select: none;
  margin-top: 10px;
  position: relative;
}
.form-profile-input input::placeholder {
  color: #b3b3b3;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
.form-profile-input input:-webkit-autofill,
.form-profile-input input:-webkit-autofill:hover,
.form-profile-input input:-webkit-autofill:focus,
.form-profile-input input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f4f5f9 inset !important;
}
.form-profile-input input:-webkit-autofill {
  -webkit-text-fill-color: #011b60 !important;
}
.dropDownArrow,
.Dropdown-placeholder {
  position: absolute;
  top: 12px;
  cursor: pointer;
}
.dropDown-input {
  cursor: pointer;
}
.Dropdown-Menu {
  position: absolute;
  background-color: #d0d0d0;
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
  z-index: 200;
  cursor: pointer;
}
.Dropdown-Menu > .Dropdown-option {
  padding: 5px;
  font-family: "Montserrat", sans-serif;
}
.Dropdown-Menu > .Dropdown-option:hover {
  background-color: #eeeeee;
}
.Dropdown-placeholder {
  color: #011b60;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
.drop-label {
  color: #011b60;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: -22px;
  padding-left: 15px;
}

@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
  .form-input {
    height: 48px;
  }
  .form-input input:focus ~ .input-label,
  .form-input input:valid ~ .input-label {
    transform: translateY(-30px);
  }
}
