.project-page {
  margin-top: 100px;
  width: 100%;
}

.project-page .page-content .page-header {
  height: 120px;
  background-color: #002c5f;
  color: #ffffff;
}
.page-header .h-box {
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.h-box .head {
  font-size: 40px;
  font-weight: normal;
  font-family: "Times New Roman", Times, serif;
}
.h-box .btn-proj {
  width: 200px;
}
.btn-proj .button {
  background-color: #fff;
  color: #162d5c;
  height: 48px;
  transition: all 0.2s ease;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .btn-proj .button:hover {
  color: #fff;
  background-color: #252525;
} */

@media (max-width: 68.75em) {
  /* For Tab-Land  68.75em= 1100px/16px */

  .h-box .head {
    font-size: 35px;
  }
}
@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
  .body-box .body-grid {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
  .page-header .h-box {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
  }
  .h-box .head {
    font-size: 26px;
  }
}
