.activity-page {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 150;
}
.edit-page {
  background-color: rgba(0, 0, 0, 0.05);
}
.activity-page .activity-box {
  width: 520px;
  background-color: #fff;
  border-radius: 15px;
  padding: 45px 60px 60px 60px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}
.activity-page .activity-box .activity-close {
  position: absolute;
  top: 40px;
  right: 65px;
  cursor: pointer;
  font-size: 18px;
}
.activity-box {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}
.activity-box .header {
  font-size: 20px;
  font-weight: 800;
  color: #002c5f;
}

.activity-box .activity-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.activity-form .form-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}
.form-field label {
  font-size: 16px;
  color: #002c5f;
  font-weight: 600;
}
.form-field input,
.form-field select,
.d-dropDown {
  height: 48px;
  width: 100%;
  display: block;
  background: none;
  border: 2px solid rgba(1, 27, 96, 0.8);
  border-radius: 5px;
  opacity: 1;
  color: #002c5f;
  font-size: 20px;
  padding: 10px 20px;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
}
.form-field input:focus,
.form-field select:focus {
  outline: none;
}
.form-field select option {
  width: 100%;
}
.form-field select option:hover {
  background: #002c5f;
}

.activity-form .activity-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.activity-form .button {
  height: 48px;
}
.d-arrow {
  position: absolute;
}
.d-Menu {
  width: 100%;
  box-sizing: border-box;
}
@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
}
