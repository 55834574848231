.admin-dash {
  margin-top: 100px;
  width: 100%;
}
.admin-dash .dash-page {
  max-width: 1470px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  gap: 10px;
  transform: translateX(-18px);
}
.dash-page .dash-left {
  width: 35%;
}
.dash-left .left-analytic {
  width: 100%;
  min-height: 700px;
  background: #002c5f;
  display: flex;
  flex-direction: column;
  padding: 50px 50px 50px 50px;
  gap: 30px;
}
.left-analytic .analytic-sub {
  font-family: "Times New Roman", Times, serif;
  font-size: 14px;
  color: #fff;
}

.left-analytic .analytic-head {
  font-family: "Times New Roman", Times, serif;
  font-size: 36px;
  color: #fff;
}
/* .edit-button{
   padding-left: 20px;
   background: transparent;
   color: #fff;
   width: 30px;
   gap: 35px;
}   */
.analytic-box {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.analytic-main {
  width: 100%;
  height: 120px;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  gap: 20px;
}
.analytic-main .main-icon {
  width: 30%;
  display: flex;
  align-items: center;
}
.main-icon img {
  width: 100%;
  height: 60px;
  object-fit: contain;
}
.analytic-main .main-view {
  display: flex;
  width: 100%;
}
.main-view .main-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}
.main-view .main-head {
  font-family: "Times New Roman", Times, serif;
  color: #002c5f;
  font-size: 24px;
}
.main-view .main-metric {
  color: #002c5f;
  font-size: 36px;
  font-weight: 800;
}
.left-analytic .btn {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 15px;
  margin-bottom: 40px;
}
.btn .button {
  width: 48%;
  height: 48px;
  background-color: #fff;
  color: #002c5f;
}
.left-analytic .left-rights {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.admin-dash .dash-right {
  width: 65%;
  background: #fff;
}
.dash-body {
  width: 100%;
  min-height: 100vh;
  max-height: 700px;
  overflow-y: scroll;
  /* padding-right: 17px; */
  box-sizing: content-box;
  position: relative;
}
.dash-body::-webkit-scrollbar {
  display: none;
}
.dash-body .dash-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
}

.dash-body .pagination {
  width: fit-content;
  padding-top: 100px;
  padding-bottom: 55px;
  margin: auto;
  font-size: 14px;
  font-weight: 500;
}
.pagination div:last-child {
  margin-left: 20px;
}

@media (max-width: 1200px) {
  .admin-dash .dash-page {
    flex-direction: column;
    transform: translateX(0px);
  }
  .dash-page .dash-left {
    width: 100%;
  }
  .dash-left .left-analytic {
    min-height: auto;
  }
  .dash-page .dash-right {
    width: 100%;
  }
  .analytic-box {
    flex-direction: row;
    gap: 30px;
  }
}

@media (max-width: 700px) {
  .dash-left .left-analytic {
    min-height: auto;
    padding: 30px 20px 20px 20px;
  }
  .dash-page .dash-right {
    width: 100%;
  }
  .analytic-box {
    flex-direction: column;
    gap: 30px;
  }
  .dash-body .dash-grid {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
}
