.nav-bar {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 10;
  border-bottom: 1px solid #e0e0e0;
}
.nav-bar .nav-box {
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-box .nav-logo {
  width: 150px;
  cursor: pointer;
}
.nav-logo img {
  width: 100%;
}
.nav-content {
  width: auto;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  transition: all 200ms ease-out;
}
.nav-content .button {
  width: 200px;
}
.nav-content ul li {
  display: inline-block;
  padding: 0 10px;
  margin: auto 30px;
  font-size: 14px;
  color: #9297a3;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.nav-content ul li:hover {
  color: #002c5f;
}
.nav-content ul .li-active {
  color: #002c5f;
}

.nav-box .nav-open,
.nav-close {
  display: none;
}

@media (max-width: 950px) {
  .nav-content {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background: rgba(0, 44, 95, 0.9);
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
  }
  .nav-content.active {
    left: 0;
  }
  .nav-content ul {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 0;
  }
  .nav-content ul li {
    color: #002c5f;
    padding: 20px 0px;
    text-align: center;
    width: 200px;
    margin: 0;
    border-radius: 5px;
    background-color: #fff;
    transition: all 0.2s ease;
  }
  .nav-content ul li:hover {
    background: #252525;
    color: #fff;
  }

  .nav-content .button {
    width: 200px;
    padding: 0px 50px;
    background-color: #fff;
    color: #0d2e5f;
    border: none;
  }
  .nav-content .button:hover {
    background: #252525;
    color: #fff;
  }

  .nav-box .nav-open {
    display: block;
    font-size: 35px;
    color: #0d2e5f;
    transform: translateX(-15px);
    cursor: pointer;
  }
  .nav-close {
    display: block;
    color: #fff;
    font-size: 40px;
    position: absolute;
    top: 26px;
    right: 30px;
    cursor: pointer;
  }
}

@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
  .my-acc-page .acc-box {
    width: 90%;
    padding: 45px 30px 30px 30px;
  }
  .sm {
    flex-direction: column;
  }
  .header .icon-load {
    padding: 10px 20px;
  }
}

.dropdown {
  display: inline-block;
  position: relative;
  padding: 20px 5px;
  margin-right: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 0;
  right: 14px;
  width: 150px;
  overflow: auto;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #fff;
  z-index: 100;
  cursor: pointer;
  text-align: center;
}
.dropdown:hover > .dropdown-content {
  display: block;
}
.dropdown-content span {
  display: block;
  color: #002c5f;
  font-weight: 500;
  padding: 10px 20px;
  text-decoration: none;
}
.dropdown-content span:hover {
  background-color: #dadde1;
}
