.details-page {
  margin-top: 100px;
  width: 100%;
}

.details-page .det-page-content .det-page-header {
  height: 150px;
  background-color: #002c5f;
  color: #ffffff;
}
.det-page-header .det-header-box {
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.det-header-box .head {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 40px;
  font-weight: normal;
  font-family: "Times New Roman", Times, serif;
}
.det-header-box .head .sub {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  gap: 20px;
  text-transform: capitalize;
  flex-direction: row;
  align-items: center;
}
.sub .divide {
  width: 1.5px;
  background: #fff;
  height: 30px;
}
.det-header-box .progress {
  display: flex;
  align-items: center;
  gap: 30px;
}
.progress .cp-sub {
  text-align: right;
  text-transform: capitalize;
}
.det-page-content .page-body {
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 20px 70px 20px;
}
.page-body-box {
  display: flex;
}

.page-body .project-picture {
  width: 50%;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.project-picture .lg-img {
  width: 100%;
  height: 400px;
  cursor: pointer;
  background-color: #e8e8e8;
}
.lg-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lg-img video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-scroll {
  width: 100%;
  position: relative;
}
.project-picture .scroll {
  width: 100%;
  height: 124px;
  vertical-align: middle;
  display: inline-block;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  background-color: #e8e8e8;
}
.scroll > :nth-child(1) {
  margin-left: 0;
}
.scroll > :last-child {
  margin-right: 0;
}
.scroll::-webkit-scrollbar {
  display: none;
}

.sm-img {
  width: 150px;
  height: 120px;
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  position: relative;
}
.sm-img-active {
  border: 2px solid #002c5f;
}

.sm-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sm-img video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-del {
  color: #fff;
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(0, 44, 95, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid rgba(0, 44, 95, 0.1);
  cursor: pointer;
}
.img-del > * {
  font-size: 18px;
}

.scroll-left {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
}
.scroll-right {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
}
.scroll-left:hover,
.scroll-right:hover {
  background-color: #eee;
}
.project-picture .upload-btns {
  display: flex;
  gap: 30px;
}
.upload-btns .button {
  height: 48px;
  font-size: 14px;
  font-weight: 500;
}
.upload-btns > :nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.upload-btns > :nth-child(2) {
  background: #ffce00;
  border: none;
  color: #002c5f;
}
.upload-btns > :nth-child(2):hover {
  background: #252525;
  color: #fff;
}

.page-body .project-activity {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.project-activity .activity-head-head {
  padding: 20px 20px;
  background-color: #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: #002c5f;
  font-weight: 700;
}
.activity-head-head .activities-head {
  width: 50%;
}
.activity-head-head .status-head {
  width: 35%;
  text-align: right;
}
.activity-head-head .action-head {
  width: 10%;
}
.activity-body {
  padding: 0px 15px 5px 15px;
  max-height: 472px;
  overflow: hidden;
}
.activity-display {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding-bottom: 50px;
}
.activity-display::-webkit-scrollbar {
  display: none;
}
.ad-p-activity {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.ad-p-activity .ad-activity-key {
  color: #002c5f;
  font-size: 16px;
  font-weight: 500;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ad-p-activity > :nth-child(2) {
  width: 40%;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ad-p-activity .ad-activity-action {
  width: 10%;
  text-align: center;
  color: #002c5f;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ad-activity-action > * {
  cursor: pointer;
}
.ad-p-activity .completed {
  font-weight: 800;
  color: #51ac06;
  font-size: 18px;
}
.ad-p-activity .ongoing {
  font-weight: 800;
  color: #ff9d00;
  font-size: 18px;
  text-transform: capitalize;
}
.ad-p-activity .yet {
  font-weight: 800;
  color: #ff0202;
  font-size: 18px;
}
.project-activity .activity-btns {
  margin-top: 63px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
}
.activity-btns > :nth-child(1) {
  border: #002c5f 2px dashed;
  background: #fff;
  color: #002c5f;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plus {
  font-weight: lighter;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activity-btns > :nth-child(2) {
  width: 200px;
  height: 48px;
}
.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 0;
  right: 14px;
  width: 150px;
  overflow: auto;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #fff;
  z-index: 10;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content span {
  display: block;
  color: #002c5f;
  font-weight: 500;
  padding: 10px 20px;
  text-decoration: none;
}
.dropdown-content span:hover {
  background-color: #dadde1;
}
.manager-edit {
  background-color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #002c5f;
  cursor: pointer;
}

/* MEDIA QUERY */

@media (max-width: 950px) {
  .page-body-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .page-body .project-picture {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .page-body .project-activity {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
  .body-box .body-grid {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
  .details-page .det-page-content .det-page-header {
    height: auto;
  }
  .det-page-header .det-header-box {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: space-between; */
    gap: 10px;
  }
  .det-header-box .head {
    font-size: 26px;
    text-align: center;
  }

  .det-header-box .sub {
    font-size: 14px;
  }
}

/* .btn-proj {
  background-color: #fff;
  color: #162d5c;
  height: 48px;
  transition: all 0.2s ease;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
} */
