.cl-page {
  margin-top: 100px;
  width: 100%;
}

.cl-page .page-content .page-header {
  min-height: 120px;
  width: 100%;
  background-color: #002c5f;
  color: #ffffff;
  display: flex;

  align-items: center;
}
.page-header .cl-h-box {
  max-width: 1440px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.cl-h-box .head {
  font-size: 40px;
  font-weight: normal;
  font-family: "Times New Roman", Times, serif;
}
.cl-h-box .header {
  display: flex;
  width: 70%;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}
.header .search-bar {
  width: calc(100% - 200px);
  height: 48px;
  position: relative;
}
.search-bar input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: none;
  padding: 0 50px 0 20px;
  color: #002c5f;
  font-size: 16px;
}
.search-bar input::placeholder {
  color: #95a4b3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-bar input:focus {
  outline: none;
}
.search-bar .search-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #95a4b3;
  cursor: pointer;
}
.cl-h-box .btn-proj {
  width: 200px;
}
.btn-proj .button {
  background-color: #fff;
  color: #002c5f;
  height: 48px;
  transition: all 0.2s ease;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-proj .button:active {
  color: #fff;
  background-color: #252525;
}
.page-content .page-body {
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 20px 100px 20px;
  background-color: #f4f5f9;
  min-height: 80vh;
  position: relative;
}

.page-body .pagination {
  width: fit-content;
  padding-bottom: 55px;
  margin: auto;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.body-box {
  width: 100%;
  padding-bottom: 50px;
}
.body-box .body-table {
  width: 100%;
  text-align: left;
}
.table-head {
  width: 100%;
  background-color: #fcfcfc;
  padding: 20px 25px;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #002c5f;
}
.table-head > * {
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-head > :last-child {
  width: 40%;
}
.table-row {
  width: 100%;
  background-color: #fcfcfc;
  padding: 20px 20px;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #002c5f;
}
.table-row:hover {
  background-color: #dadde1;
}
.table-row > :first-child {
  text-transform: capitalize;
}
.table-row > :not(:last-child) {
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-row > :nth-child(4) {
  width: 35%;
}
.table-row > :nth-child(5) {
  width: 5%;
  text-align: center;
}
.table-row .row-action {
  cursor: pointer;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 0;
  right: 14px;
  width: 150px;
  overflow: auto;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #fff;
  z-index: 100;
}
.dropdown:hover > .dropdown-content {
  display: block;
}
.dropdown-content span {
  display: block;
  color: #002c5f;
  font-weight: 500;
  padding: 10px 20px;
  text-decoration: none;
}
.dropdown-content span:hover {
  background-color: #dadde1;
}

.list-skeleton {
  width: 100%;
  height: 50px;
  padding: 5px;
  margin-bottom: 20px;
}
@media (max-width: 800px) {
  .page-header .cl-h-box {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .cl-h-box .header {
    flex-direction: column;
    align-items: flex-end;
  }
  .header .search-bar {
    width: 200px;
  }
}
@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
  .body-box .body-grid {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
  .page-header .h-box {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
  }
  .header-box .head {
    font-size: 26px;
  }

  .header-box .sub {
    font-size: 14px;
  }
}
