.dashboard-page {
  margin: 0 auto;
  margin-top: 100px;
  width: 100%;
}

.dashboard-page .page-content .page-header {
  height: 200px;
  background-color: #002c5f;
  color: #ffffff;
}
.page-header .header-box {
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}
.header-box .head {
  font-size: 40px;
  font-weight: normal;
  font-family: "Times New Roman", Times, serif;
}

.header-box .sub {
  font-size: 18px;
  font-weight: 500;
}
.page-content .page-body {
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 20px 100px 20px;
  position: relative;
}
.page-body .pagination {
  width: fit-content;
  padding-bottom: 55px;
  margin: auto;
  font-size: 14px;
  font-weight: 500;
}
.pagination div:last-child {
  margin-left: 20px;
}
.body-box {
  width: 100%;
}
.body-box .body-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  padding-bottom: 50px;
}
.no-data {
  width: 100%;
  position: absolute;
  top: 100px;
  left: 42%;
  font-size: 20px;
  font-weight: 500;
}
.no-data > div > svg {
  transform: translate3d(-100px, 0, 0) !important;
}
@media (max-width: 68.75em) {
  /* For Tab-Land  68.75em= 1100px/16px */
  .body-box .body-grid {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  .header-box .head {
    font-size: 35px;
  }

  .header-box .sub {
    font-size: 16px;
  }
}
@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
  .body-box .body-grid {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
  .header-box .head {
    font-size: 26px;
  }

  .header-box .sub {
    font-size: 14px;
  }
}
