.comment-box {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}
.comment-box .header {
  font-size: 20px;
  font-weight: 800;
  color: #002c5f;
}

.comment-box .comment-form {
  width: 100%;
}
.comment-form textarea {
  width: 100%;
  height: 200px;
  background-color: #f4f5f9;
  border: none;
  padding: 10px;
  color: #002c5f;
  resize: none;
}
.comment-box textarea:focus {
  outline: none;
}
.comment-form .comment-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.comment-form .button {
  text-align: center;
  width: 150px;
  height: 48px;
}

@media (max-width: 37.5em) {
  /* For Phone  37.5em= 600px/16px */
}
